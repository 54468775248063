import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Stack,
  Typography,
  Avatar,
} from "@mui/material";

import React from "react";
import PichelLogo from "../../assets/pichel.png";
import EminenceLogo from "../../assets/eminencelogo.png";
import TPDLogo from "../../assets/TPD-logo.jpg";

function ReportHeader({ type }) {
  let head = "";
  let image_logo = "";
  switch (type) {
    case 1:
      image_logo = PichelLogo;
      head = (
        <>
          <Typography variant="h6" fontWeight={700}>
            PICHEL SECURITY AND DETECTIVE AGENCY
          </Typography>
          <Typography variant="h6">TETUAN, ZAMBOANGA CITY</Typography>
          <Typography variant="h6" fontWeight={700}>
            MASTER LIST
          </Typography>
        </>
      );
      break;
    case 3:
      head = (
        <>
          <Typography variant="h6" fontWeight={700}>
            SSP MANPOWER SERVICES
          </Typography>
          <Typography variant="h6">TETUAN, ZAMBOANGA CITY</Typography>
          <Typography variant="h6" fontWeight={700}>
            MASTER LIST
          </Typography>
        </>
      );
      break;
    case 2:
      image_logo = EminenceLogo;
      head = (
        <>
          <Typography variant="h6" fontWeight={700}>
            EMINENCE SECURITY AND DETECTIVE AGENCY
          </Typography>
          <Typography variant="h6">TETUAN, ZAMBOANGA CITY</Typography>
          <Typography variant="h6" fontWeight={700}>
            MASTER LIST
          </Typography>
        </>
      );
      break;
    default:
      image_logo = TPDLogo;
      break;
  }

  let content = "";
  content = (
    <Stack
      spacing={1}
      direction={"row"}
      sx={{ p: 2, justifyContent: "center" }}
    >
      <Stack direction={"column"} spacing={0} sx={{ textAlign: "center" }}>
        <img
          src={image_logo}
          alt="Pichel Logo"
          width="80px"
          height="100px"
          style={{ marginRight: 2 }}
        />
      </Stack>
      <Stack direction={"column"} spacing={0} sx={{ textAlign: "center" }}>
        {head}
      </Stack>
    </Stack>
  );
  return content;
}

export default ReportHeader;
