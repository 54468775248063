import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";

// Styles for the table
const stylesTable = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 14,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
  },
  tableCellHeader: {
    fontWeight: "bold",
    backgroundColor: "#f3f3f3",
  },
  tableCell: {
    textAlign: "center",
  },
});

// Styles for the leave data
const stylesLeaves = StyleSheet.create({
  section: {
    marginBottom: 20,
  },
  row: {
    flexDirection: "row",
    marginBottom: 5,
  },
  label: {
    fontWeight: "bold",
    marginRight: 5,
  },
  value: {
    flex: 1,
  },
  signatureContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  signature: {
    borderTop: "1px solid #000",
    width: "40%",
    textAlign: "center",
  },
});

// Create Table Data
const createTableData = () => {
  const sampleData = [
    { id: 1, name: "John Doe", position: "Developer", salary: "$5000" },
    { id: 2, name: "Jane Smith", position: "Designer", salary: "$4500" },
    { id: 3, name: "Mike Johnson", position: "Manager", salary: "$7000" },
  ];

  return (
    <>
      <Text style={{ marginBottom: 10, fontSize: 16, fontWeight: "bold" }}>
        Employee Data
      </Text>
      <View style={stylesTable.table}>
        <View style={stylesTable.tableRow}>
          <Text style={[stylesTable.tableCol, stylesTable.tableCellHeader]}>
            ID
          </Text>
          <Text style={[stylesTable.tableCol, stylesTable.tableCellHeader]}>
            Name
          </Text>
          <Text style={[stylesTable.tableCol, stylesTable.tableCellHeader]}>
            Position
          </Text>
          <Text style={[stylesTable.tableCol, stylesTable.tableCellHeader]}>
            Salary
          </Text>
        </View>
        {sampleData.map((row, index) => (
          <View style={stylesTable.tableRow} key={index}>
            <Text style={stylesTable.tableCol}>{row.id}</Text>
            <Text style={stylesTable.tableCol}>{row.name}</Text>
            <Text style={stylesTable.tableCol}>{row.position}</Text>
            <Text style={stylesTable.tableCol}>{row.salary}</Text>
          </View>
        ))}
      </View>
    </>
  );
};

const createStyleaLeave = StyleSheet.create({
  div: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
});

const leaveForm = () => (
  <View style={createStyleaLeave.div}>
    <View>
      <View>
        <Text>FORM</Text>
      </View>
      <View>
        <Text>LEAVE FORM</Text>
      </View>
    </View>
    <View>
      <View>
        <Text>TRACKING NO:</Text>
      </View>
      <View>
        <Text>APPLICATION FOR LEAVE</Text>
      </View>
    </View>
    <View>
      <View>
        <Text>OFFICE DEPARTMENT:</Text>
      </View>
      <View>
        <Text>EMPLOYEE NO:</Text>
      </View>
      <View>
        <Text>NAME:</Text>
        <Text>(LASTNAME)</Text>
        <Text>(FIRSTNAME)</Text>
        <Text>(MIDDLENAME)</Text>
      </View>
    </View>
    <View>
      <View>
        <Text>DATE OF FILING:</Text>
      </View>
      <View>
        <Text>POSITION:</Text>
      </View>
      <View>
        <Text>MONTHLY SALARY (BASIC)</Text>
      </View>
    </View>
    <View>
      <Text>DETAILS OF APPLICATION</Text>
    </View>
    <View>
      <View>
        <Text>TYPE OF LEAVE:</Text>
      </View>
      <View>
        <Text>Vacation (Status purpose/reason)</Text>
      </View>
      <View>
        <Text>Others (Specify)</Text>
      </View>
    </View>
  </View>
);

// Create Leave Data
const createLeaveData = (data) => (
  <View style={stylesLeaves.section}>
    <View style={stylesLeaves.row}>
      <Text style={stylesLeaves.label}>Name: </Text>
      <Text style={stylesLeaves.value}>{data.name}</Text>
    </View>
    <View style={stylesLeaves.row}>
      <Text style={stylesLeaves.label}>Type of Leave: </Text>
      <Text style={stylesLeaves.value}>{data.type_of_leave}</Text>
    </View>
    <View style={stylesLeaves.row}>
      <Text style={stylesLeaves.label}>Leave Reason: </Text>
      <Text style={stylesLeaves.value}>{data.leave_reason}</Text>
    </View>
    <View style={stylesLeaves.row}>
      <Text style={stylesLeaves.label}>Start Date: </Text>
      <Text style={stylesLeaves.value}>
        {dayjs(data.start_date).format("MMMM D, YYYY")}
      </Text>
    </View>
    <View style={stylesLeaves.row}>
      <Text style={stylesLeaves.label}>End Date: </Text>
      <Text style={stylesLeaves.value}>
        {dayjs(data.end_date).format("MMMM D, YYYY")}
      </Text>
    </View>
    <View style={stylesLeaves.row}>
      <Text style={stylesLeaves.label}>Duration: </Text>
      <Text style={stylesLeaves.value}>
        {dayjs(data.end_date).diff(dayjs(data.start_date), "days")} days
      </Text>
    </View>
    <View style={stylesLeaves.signatureContainer}>
      <View style={stylesLeaves.signature}>
        <Text>Signature</Text>
      </View>
      <View style={stylesLeaves.signature}>
        <Text>Signature</Text>
      </View>
    </View>
  </View>
);

// Create Document Component
const CreateDocument = ({ size, orientation, data }) => (
  <Document>
    <Page size={size} orientation={orientation} style={stylesTable.page}>
      {/*createLeaveData(data)*/}
      {leaveForm()}
    </Page>
  </Document>
);

export default CreateDocument;
