import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  alpha,
  styled,
  Menu,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Divider from "@mui/material/Divider";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    maxWidth: 450,
    width: "100%",
    "& media (min-width: 600px)": {
      with: 200,
    },

    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

export default function ReportMenu({ categories, handleClickGenerate }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [branchCategory, setBranchCategory] = React.useState([]);
  const open = Boolean(anchorEl);
  const [selectedBranch, setSelectedBranch] = React.useState("0");
  const [typeOfReport, setTypeOfReport] = React.useState("0");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (categories) {
      const cat = [];
      categories.forEach((branch) => {
        cat.push({
          title: branch.title,
          value: branch.value,
          id: branch.id,
        });
      });
      setBranchCategory(cat);
    }
  }, [categories]);

  const handleChangeBranch = (event) => {
    setSelectedBranch(event.target.value);
  };

  const handleTypeOfReport = (event) => {
    setTypeOfReport(event.target.value);
  };

  let content = "";
  content = (
    <>
      <Paper
        elevation={3}
        sx={{
          xs: 12,
          md: 6,
          mx: "auto",
          mt: 2,
          p: 2,
          borderRadius: 2,
          minWidth: 300,
        }}
      >
        <Stack spacing={2} sx={{ p: 2 }}>
          <Stack direction={"column"} spacing={2}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
            >
              Manage Reports
            </Typography>
            <FormControl fullWidth>
              <Select
                value={typeOfReport}
                sx={{ width: "100%" }}
                onChange={handleTypeOfReport}
              >
                <MenuItem value="0" disabled selected>
                  Type of Report
                </MenuItem>
                <MenuItem value="1">Master List</MenuItem>
                <MenuItem value="5">Client List</MenuItem>
                <MenuItem value="2" disabled>
                  Attendance Report
                </MenuItem>
                <MenuItem value="3" disabled>
                  Leave Report
                </MenuItem>
                <MenuItem value="4" disabled>
                  Payroll Report
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <Select
                value={selectedBranch}
                sx={{ width: "100%" }}
                onChange={handleChangeBranch}
              >
                <MenuItem value="0" disabled selected>
                  Branch Name
                </MenuItem>
                {branchCategory.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {`${branch.title}`}{" "}
                    {typeOfReport === "1" ? `(${branch.value})` : ""}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={false}
                sx={{ height: "100%" }}
                loadingPosition="end"
                onClick={() =>
                  handleClickGenerate(selectedBranch, typeOfReport)
                }
              >
                Generate Report
              </LoadingButton>
            </FormControl>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
  return content;
}
