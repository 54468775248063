import React from "react";
import MasterList from "./pages/MasterList";

//import "./HRStyle.css";

function Reports() {
  return <MasterList />;
}

export default Reports;
