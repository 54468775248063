import React from "react";
import { useParams } from "react-router-dom";

import Reports from "./Reports";
import MasterList from "./pages/MasterList";

//import "./HRStyle.css";

function ManageReports() {
  const { id, type } = useParams();
  let content = null;
  switch (type) {
    case "master-list":
      content = <MasterList />;
      break;
    case "manage-clients":
      //content = <ManageAOR />;
      break;
    default:
      content = <Reports />;
      break;
  }
  return content;
}

export default ManageReports;
