import React, { useEffect, useRef } from "react";
import IconBreadcrumbs from "../../../components/shared/Breadcrumb";
import ReportMenu from "../../../components/reportN/ReportMenu";
import ReportBody from "../ReportBody";
import { useBranchWidgetQuery } from "../../../app/api/reports/dashboardApiSlice";
import { useMasterListReportQuery } from "../../../app/api/reports/reportApiSlice";
import PDFReportBody from "../../../components/PDF-report/PDFReportBody";
import { PDFViewer } from "@react-pdf/renderer";
import { Paper, Stack, Typography } from "@mui/material";

function MasterList() {
  const reportTemplateRef = useRef();
  const { data, isLoading } = useBranchWidgetQuery({
    refetchOnMountOrArgChange: true,
  });

  const [branchCategory, setBranchCategory] = React.useState([]);
  const [reportResult, setReportResult] = React.useState([]);
  const [selectedBranch, setSelectedBranch] = React.useState(0);
  const [triggerGenerateReport, setTriggerGenerateReport] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [typeOfReport, setTypeOfReport] = React.useState("0");

  const { data: reportData, isLoading: reportIsLoading } =
    useMasterListReportQuery(
      { branch_no: selectedBranch },
      {
        refetchOnMountOrArgChange: true,
        skip: !triggerGenerateReport,
      }
    );
  const handleGenerateReport = (branch_no, typeOfReport) => {
    setSelectedBranch(branch_no);
    setTypeOfReport(typeOfReport);
    setTriggerGenerateReport(true);
    console.log(typeOfReport);
  };

  useEffect(() => {
    if (reportData) {
      setReportResult(reportData);
      setTriggerGenerateReport(false);
    }
  }, [reportData, reportIsLoading]);

  useEffect(() => {
    if (data) {
      console.log(data);
      const categories = [];

      data.branch.forEach((branch) => {
        categories.push({
          title: branch.name,
          value: 0,
          id: branch.id,
        });
      });

      data.countUserPerBranch.forEach((branch) => {
        categories.forEach((category) => {
          if (branch._id === category.id) {
            category.value = branch.count;
          }
        });
      });
      setBranchCategory(categories);
    }
  }, [data, isLoading]);

  const handleDisplayPDFViewer = () => {
    // Trigger the PDF viewer directly in the UI
    return (
      <PDFViewer style={{ width: "100%", height: "90vh" }}>
        <PDFReportBody
          bodyContent={reportResult}
          generateReport={triggerGenerateReport}
          type={typeOfReport}
          branch_id={selectedBranch}
        />
      </PDFViewer>
    );
  };

  return (
    <>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <ReportMenu
          categories={branchCategory}
          handleClickGenerate={handleGenerateReport}
        />
        <Paper sx={{ flexGrow: 1, borderRadius: 2 }}>
          {handleDisplayPDFViewer()}
        </Paper>
      </Stack>
    </>
  );
}

export default MasterList;
