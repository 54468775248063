// pageRoutes.js
import React from "react";
import CustomRoute from "./CustomRoutes";
import Login from "../features/login/Login";
import Dashboard from "../features/dashboard/Dashboard";
import Employee from "../features/admin/employee/Employee";
import Attendance from "../features/admin/attendance/Attendance";
import Payroll from "../features/admin/payroll/Payroll";
import Page404 from "../features/404/Page404";
import { ROLES } from "../config/roles";
import Administrative from "../features/admin/administrative/Administrative.js";
import EmployeeDTR from "../features/frontend/EmployeeDTR.js";
import Create13thMonth from "../features/admin/payroll/pages/Create13thMonth.js";
import ManageAOR from "../features/admin/administrative/area_assign/ManageAOR.js";
import ManageBranch from "../features/admin/administrative/branch/ManageBranch.js";
import ManagePassword from "../features/admin/administrative/employee/ManagePassword.js";
import ManageReports from "../features/printReport/ManageReports.js";

const allRoles = Object.values(ROLES);

// Utility function to generate CustomRoute elements
const createCustomRoute = (component, allowedRoles, title) => (
  <CustomRoute
    component={component}
    allowedRoles={allowedRoles}
    title={title}
  />
);

const router = [
  { path: "/login", element: <Login />, title: "Login Page", isHidden: true },
  {
    path: "/dashboard",
    element: createCustomRoute(
      Dashboard,
      [
        ROLES.Admin,
        ROLES.Manager,
        ROLES.payroll_clerk,
        ROLES.HR,
        ROLES.encoder,
      ],
      "Home"
    ),
    title: "Dashboard",
    isHidden: false,
  },
  /*{
    path: "/administration",
    items: [
      {
        path: "/manage-client",
        name: "Manage Client",
        element: createCustomRoute(
          Administrative,
          [ROLES.Admin],
          "Manage Assignments"
        ),
        title: "Manage Client",
      },
      {
        path: "/manage-assignments",
        name: "Manage Assignments",
        element: createCustomRoute(
          Administrative,
          [ROLES.Admin],
          "Manage Assignments"
        ),
        title: "Manage Assignments",
      },
    ],
    title: "Administration",
    isHidden: false,
  },*/
  {
    path: "/manage-password/:type",
    element: createCustomRoute(
      ManagePassword,
      [ROLES.Admin],
      "Manage Password"
    ),
    title: "Manage Password",
    isHidden: true,
  },
  {
    path: "/manage-password/:type/:id",
    element: createCustomRoute(
      ManagePassword,
      [ROLES.Admin],
      "Manage Password"
    ),
    title: "Manage Password",
    isHidden: true,
  },
  {
    path: "/administrative",
    element: createCustomRoute(Administrative, [ROLES.Admin], "Administrative"),
    title: "Administrative",
    isHidden: false,
  },
  {
    path: "/administrative/:type",
    element: createCustomRoute(Administrative, [ROLES.Admin], "Administrative"),
    title: "Administrative",
    isHidden: true,
  },
  {
    path: "/manage-assignments",
    element: createCustomRoute(
      ManageAOR,
      [ROLES.Admin, ROLES.HR],
      "Employee data management"
    ),
    title: "Employee data management",
    isHidden: true,
  },
  {
    path: "/manage-client",
    element: createCustomRoute(ManageBranch, [ROLES.Admin], "Client Name"),
    title: "Client Name",
    isHidden: true,
  },
  {
    path: "/manage-client/:type",
    element: createCustomRoute(ManageBranch, [ROLES.Admin], "Client Name"),
    title: "Client Name",
    isHidden: true,
  },
  {
    path: "manage-client/:type/:id",
    element: createCustomRoute(ManageBranch, [ROLES.Admin], "Client Name"),
    title: "Client Name",
    isHidden: true,
  },
  {
    path: "/manage-employees",
    element: createCustomRoute(
      Employee,
      [ROLES.Admin, ROLES.HR, ROLES.enocder],
      "Employee data management"
    ),
    title: "Human Resource Management",
    isHidden: false,
  },
  {
    path: "/manage-employees/:type",
    element: createCustomRoute(
      Employee,
      [ROLES.Admin, ROLES.HR],
      "Employee data management"
    ),
    title: "Employee data management",
    isHidden: true,
  },
  {
    path: "/manage-employees/:type/:id",
    element: createCustomRoute(
      Employee,
      [ROLES.Admin, ROLES.HR],
      "Employee data management"
    ),
    title: "Employee data management",
    isHidden: true,
  },
  {
    path: "/manage-attendance",
    element: createCustomRoute(
      Attendance,
      [ROLES.Admin, ROLES.enocder],
      "Time & attendance management"
    ),
    title: "Time & attendance management",
    isHidden: false,
  },
  {
    path: "/manage-attendance/:type",
    element: createCustomRoute(
      Attendance,
      [ROLES.Admin, ROLES.enocder],
      "Time & attendance management"
    ),
    title: "Time & attendance management",
    isHidden: true,
  },
  {
    path: "/manage-attendance/:type/:id/:name/:year/:month/:period",
    element: createCustomRoute(
      Attendance,
      [ROLES.Admin, ROLES.enocder],
      "Time & attendance management"
    ),
    title: "Time & attendance management",
    isHidden: true,
  },
  {
    path: "/manage-payroll",
    element: createCustomRoute(
      Payroll,
      [ROLES.Admin, ROLES.payroll_clerk],
      "Payroll processing"
    ),
    title: "Payroll processing",
    isHidden: false,
  },
  {
    path: "/manage-13th-month-pay",
    element: createCustomRoute(
      Create13thMonth,
      [ROLES.Admin],
      "13th month Payroll"
    ),
    title: "13th month Payroll",
    isHidden: true,
  },
  {
    path: "/manage-payroll/:type",
    element: createCustomRoute(
      Payroll,
      [ROLES.Admin, ROLES.payroll_clerk],
      "Payroll processing"
    ),
    title: "Payroll processing",
    isHidden: true,
  },
  {
    path: "/manage-payroll/:type/:id",
    element: createCustomRoute(
      Payroll,
      [ROLES.Admin, ROLES.payroll_clerk],
      "Payroll processing"
    ),
    title: "Payroll processing",
    isHidden: true,
  },
  {
    path: "/manage-reports",
    element: createCustomRoute(
      ManageReports,
      [ROLES.Admin, ROLES.HR, ROLES.payroll_clerk, ROLES.encoder],
      "Reporting and analytics"
    ),
    title: "Reports",
    isHidden: false,
  },
  {
    path: "/manage-reports/:type",
    element: createCustomRoute(
      ManageReports,
      [ROLES.Admin, ROLES.HR, ROLES.payroll_clerk, ROLES.encoder],
      "Reporting and analytics"
    ),
    title: "Reports",
    isHidden: true,
  },
  {
    path: "DTR",
    element: <EmployeeDTR />,
    title: "Employee DTR",
    isHidden: true,
  },
  {
    path: "/*",
    element: createCustomRoute(Page404, allRoles, "404 Page"),
    isHidden: true,
  },
];

export default router;
