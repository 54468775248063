import React from "react";
import { Text, View, StyleSheet, Page, Document } from "@react-pdf/renderer";
import PDFHeader from "./PDFHeader";
import MasterList from "../../features/printReport/pdfReports/MasterList";
import ClientList from "../../features/printReport/pdfReports/ClientList";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#000",
    flexWrap: "wrap",
    pageBreakInside: "avoid",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f2f2f2",
    borderBottomWidth: 1,
    borderColor: "#000",
  },
  tableCell: {
    flex: 1,

    textAlign: "center",
    fontSize: 10,
    borderRightWidth: 1,
  },
  tableHeaderCell: {
    flex: 1,

    textAlign: "center",
    fontSize: 10,
    fontWeight: "bold",
    borderRightWidth: 1,
    borderColor: "#000",
    backgroundColor: "#f2f2f2",
  },
  footerText: {
    fontSize: 10,
    marginTop: 10,
    textAlign: "center",
  },
  page: {
    padding: 10,
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
  },
});

const ReportBody = ({ type, bodyContent, branch_id, generateReport }) => {
  let tableData = "";
  console.log(type);
  switch (parseInt(type)) {
    case 1:
      tableData = (
        <MasterList
          bodyContent={bodyContent}
          type={branch_id}
          generateReport={generateReport}
        />
      );
      break;
    case 5:
      tableData = <ClientList generateReport={generateReport} />;
      break;
    default:
      tableData = (
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableCell, flex: 6 }}>
              No data available
            </Text>
          </View>
        </View>
      );
      break;
  }

  const footerData = (
    <View
      style={{
        marginTop: 10,
        display: "flex",
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-between",
      }}
    >
      <View>
        <Text style={styles.footerText}>
          Prepared by: _____________________
        </Text>
      </View>
      <View>
        <Text style={styles.footerText}>
          Approved by: _____________________
        </Text>
      </View>
    </View>
  );

  return (
    <Page size={"A4"} style={styles.page} wrap>
      <PDFHeader type={branch_id} />
      {tableData}
      {footerData}
    </Page>
  );
};

const PDFReportBody = ({ bodyContent, type, branch_id, generateReport }) => (
  <Document>
    <ReportBody
      bodyContent={bodyContent}
      type={type}
      generateReport={generateReport}
      branch_id={branch_id}
    />
  </Document>
);

export default PDFReportBody;
