import React from "react";
import UserListData from "./employee/UserListData";
import ManageAOR from "./area_assign/ManageAOR";
import { useParams } from "react-router-dom";
import { Box, Card, CardContent, ButtonBase } from "@mui/material";
import {
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Assignment as AssignmentIcon,
} from "@mui/icons-material";
import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";
import ManageLeaves from "./employee/ManageLeaves";

import "./HRStyle.css";

function Administrative() {
  const { id, type } = useParams();
  let content = null;
  switch (type) {
    case "manage-users":
      content = <UserListData />;
      break;
    case "manage-clients":
      content = <ManageAOR />;
      break;
    case "manage-leaves":
      content = <ManageLeaves />;
      break;
    default:
      content = (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "100%",
              height: "500px",
              alignItems: "center",
            }}
            className="administrative-card"
          >
            <ButtonBase
              href="/administrative/manage-users"
              sx={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                width: "200px",
                height: "200px",
                marginRight: "10px",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 3,
                },
              }}
            >
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <SupervisedUserCircleIcon
                    sx={{
                      fontSize: 50,
                      color: "primary.main",
                    }}
                  />
                  <p style={{ margin: "0" }}>Manage Accounts</p>
                </CardContent>
              </Card>
            </ButtonBase>

            <ButtonBase
              href="/administrative/manage-clients"
              sx={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                width: "200px",
                height: "200px",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 3,
                },
              }}
            >
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  mr: 1,
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <AssignmentIcon
                    sx={{
                      fontSize: 50,
                      color: "primary.main",
                    }}
                  />
                  <p style={{ margin: "0" }}>Manage Clients</p>
                </CardContent>
              </Card>
            </ButtonBase>

            <ButtonBase
              href="/administrative/manage-leaves"
              sx={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                width: "200px",
                height: "200px",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 3,
                },
              }}
            >
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <MedicationOutlinedIcon
                    sx={{
                      fontSize: 50,
                      color: "primary.main",
                    }}
                  />
                  <p style={{ margin: "0" }}>Manage Leaves</p>
                </CardContent>
              </Card>
            </ButtonBase>
          </Box>
        </>
      );
      break;
  }
  return content;
}

export default Administrative;
