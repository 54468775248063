import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { useClientListReportQuery } from "../../../app/api/reports/reportApiSlice";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#000",
    flexWrap: "wrap",
    pageBreakInside: "avoid",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f2f2f2",
    borderBottomWidth: 1,
    borderColor: "#000",
  },
  tableCell: {
    flex: 1,
    padding: 5,
    textAlign: "center",
    fontSize: 10,
    borderRightWidth: 1,
    borderColor: "#000",
  },
  tableHeaderCell: {
    padding: 5,
    textAlign: "center",
    fontSize: 10,
    fontWeight: "bold",
    borderRightWidth: 1,
    borderColor: "#000",
    backgroundColor: "#f2f2f2",
  },
  footerText: {
    fontSize: 10,
    marginTop: 10,
    textAlign: "center",
  },
  page: {
    padding: 10,
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
  },
});

function ClientList() {
  const bodyContent = [];
  let tableHeaders = "";
  let tableRows = "";
  tableHeaders = (
    <View style={styles.tableHeader}>
      <Text style={{ ...styles.tableHeaderCell, width: "250px !important;" }}>
        Client Name
      </Text>
      <Text style={{ ...styles.tableHeaderCell, flexWrap: "wrap" }}>
        Number of Assign employee
      </Text>
      <Text style={styles.tableHeaderCell}>Address</Text>
      <Text style={styles.tableHeaderCell}>Contact Number</Text>
      <Text style={styles.tableHeaderCell}>Status</Text>
    </View>
  );

  tableRows =
    bodyContent && bodyContent.length > 0 ? (
      bodyContent.map((item) => (
        <View style={styles.tableRow} key={item.id}>
          <Text style={{ ...styles.tableCell, width: "300px !important;" }}>
            {`${item.lastname}, ${item.firstname} ${item.middlename}`}
          </Text>
          <Text style={styles.tableCell}>{item.job_positions}</Text>
          <Text style={styles.tableCell}>{item.area_name}</Text>
          <Text style={styles.tableCell}>{item.work_status}</Text>
          <Text style={styles.tableCell}>{item.date_hired}</Text>
          <Text style={styles.tableCell}>{item.date_end}</Text>
        </View>
      ))
    ) : (
      <View style={styles.tableRow}>
        <Text style={{ ...styles.tableCell, flex: 6 }}>No data available</Text>
      </View>
    );

  return (
    <View style={styles.table}>
      {tableHeaders}
      {tableRows}
    </View>
  );
}

export default ClientList;
