import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useGetPayrollQuery } from "../../../../app/api/payroll/generatePayroll";
import {
  Grid,
  Stack,
  Typography,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import dayjs from "dayjs";
import IconMenu from "../../../../components/buttons/ButtonList";
import { useReactToPrint } from "react-to-print";
import "./payslip.css";
import IconBreadcrumbs from "../../../../components/shared/Breadcrumb";

function decimalFormat(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function PaySlip() {
  const location = useLocation();
  const [payslipData, setPayslipData] = React.useState([]);

  // Function to parse query parameters
  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      month: params.get("month"),
      year: params.get("year"),
      period: params.get("period"),
      branch_id: params.get("branch_id"),
      user_id: params.get("user_id"),
      payroll_id: params.get("payroll_id"),
    };
  };

  const queryParams = getQueryParams(location.search);

  const { data, isLoading } = useGetPayrollQuery(
    {
      month: queryParams.month,
      year: queryParams.year,
      period: queryParams.period,
      branch_id: queryParams.branch_id,
      user_id: queryParams.user_id,
      payroll_id: queryParams.payroll_id,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const componentRef = React.useRef();
  const styleRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Custom Print Document",
    onBeforeGetContent: () => {
      // Dynamically create and add the print styles
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
        @media print {
          @page {
            size: A4 portrait !important;
          }
          .payslip-card {
            page-break-inside: avoid;
            break-inside: avoid;
          }
          .payslip-section {
            page-break-after: auto;
            break-after: auto;
          }
          .payslip-container {
            page-break-before: auto;
            break-before: auto;
          }
        }
      `;
      document.head.appendChild(styleElement);
      styleRef.current = styleElement; // Store reference to the style element
    },
    onAfterPrint: () => {
      // Remove the dynamically added style after printing
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
      }
    },
  });

  useEffect(() => {
    if (data) {
      const payslipData = [];
      data.forEach((data) => {
        let totalDeduction =
          data.v1 +
          data.v2 +
          data.par +
          data.ut +
          data.absent +
          data.par +
          data.rice +
          data.sss_rate +
          data.philhealth_rate +
          data.pagibig_rate +
          (data.loans?.monthly_payment || 0);
        let totalSalary =
          parseFloat(data.salary_rate * data.days_work) +
          parseFloat(data.salary_rate / 8) *
            parseFloat(data.overtime || 0) *
            1.25 +
          parseFloat(data.salary_rate / 8) * parseFloat(data.nd || 0) * 1.1 +
          parseFloat(data.salary_rate / 8) * parseFloat(data.rd || 0) * 1.3;
        payslipData.push(
          <>
            <Grid item xs={6} md={6}>
              <Card
                className="payslip-card"
                sx={{
                  height: "100%",
                  minHeight: "200px",
                  border: "1px solid",
                }}
              >
                <CardContent>
                  <Stack
                    spacing={0}
                    sx={{ textAlign: "center", fontSize: 1 }}
                    className="payslip-header"
                  >
                    <Typography variant="h6" sx={{ fontSize: 14 }}>
                      PICHEL SECURITY AND DETECTIVE AGENCY
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: 14 }}>
                      TETUAN, ZAMBOANGA CITY
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: 14 }}>
                      PAYSLIP {data.reliever ? "RELIEVER" : ""}
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        display: "flex",
                        textAlign: "right",
                        float: "right",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography sx={{ fontSize: 14, textAlign: "right" }}>
                        {dayjs().format("MM/DD/YY")}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{ display: "flex" }}
                    className="payslip-name"
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontSize: 14, width: "40%" }}
                    >
                      {data.last_name}, {data.first_name} {data.middle_name}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: 14,
                        float: "right",
                        width: "60%",
                        textAlign: "end",
                      }}
                    >
                      {data.area_assign}
                    </Typography>
                  </Stack>
                  <Divider
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      height: 0.75,
                    }}
                  />
                  <Stack direction="row" spacing={2} className="payslip-body">
                    <Stack direction={"column"} sx={{ width: "100%" }}>
                      <Stack direction="row" spacing={2}>
                        <Stack direction={"column"} sx={{ width: "50%" }}>
                          <Stack direction="row" spacing={2}>
                            <Typography className="basicPay">
                              BASIC PAY
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "right",
                              }}
                            >
                              ₱{decimalFormat(totalSalary)}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack direction={"column"} sx={{ width: "50%" }}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              sx={{ width: "140px" }}
                              className="basicPayRight"
                            >
                              ND
                            </Typography>
                            <Typography>{data.nd}</Typography>
                          </Stack>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              sx={{ width: "140px" }}
                              className="basicPayRight"
                            >
                              ND Amount
                            </Typography>
                            <Typography>
                              {decimalFormat(
                                (data.salary_rate / 8) *
                                  parseFloat(data.nd || 0) *
                                  1.1
                              )}
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              sx={{ width: "140px" }}
                              className="basicPayRight"
                            >
                              RD[hrs]/Amount
                            </Typography>
                            <Typography>
                              {data.rd}/
                              {decimalFormat(
                                (data.salary_rate / 8) * data.rd * 1.3
                              )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Stack direction={"column"} sx={{ width: "50%" }}>
                          <Typography>HOLIDAY PAY:</Typography>
                          <Stack direction="row" spacing={2} ml={2}>
                            <Typography
                              sx={{ width: "112px" }}
                              className="holidayPay"
                            >
                              REG
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "right",
                              }}
                            >
                              ₱{`${decimalFormat(data.salary_rate)} `}
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={2} ml={2}>
                            <Typography
                              sx={{ width: "112px" }}
                              className="holidayPay"
                            >
                              SPECIAL
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "right",
                              }}
                            >
                              ₱0.00
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack direction={"column"} sx={{ width: "50%" }}>
                          <Typography>Allowance/Refund</Typography>
                          <Stack direction="row" spacing={2} ml={2}>
                            <Typography
                              sx={{ width: "124px" }}
                              className="holidayPayRight"
                            >
                              OT Hrs
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "right",
                              }}
                            >
                              {data.overtime}
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={2} ml={2}>
                            <Typography
                              sx={{ width: "124px" }}
                              className="holidayPayRight"
                            >
                              OT Amnt
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "right",
                              }}
                            >
                              {decimalFormat(
                                data.overtime * (data.salary_rate / 8) * 1.25
                              )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>

                      <Stack direction="column" sx={{ width: "100%" }}>
                        <Typography>LESS DEDUCTION:</Typography>
                        <Stack direction="row" spacing={2}>
                          <Stack direction="column" sx={{ width: "50%" }}>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="lessDeduc"
                                sx={{ width: "112px" }}
                              >
                                ABSENCES
                              </Typography>
                              <Typography>[{data.absent}]</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="lessDeduc"
                                sx={{ width: "112px" }}
                              >
                                SH-AB
                              </Typography>
                              <Typography>0</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="lessDeduc"
                                sx={{ width: "112px" }}
                              >
                                TAX
                              </Typography>
                              <Typography>0</Typography>
                            </Stack>
                          </Stack>
                          <Stack direction="column" sx={{ width: "50%" }}>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="lessDeducRight"
                                sx={{ width: "124px" }}
                              >
                                SSS LOAN
                              </Typography>
                              <Typography>0</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="lessDeducRight"
                                sx={{ width: "124px" }}
                              >
                                PAG LOAN
                              </Typography>
                              <Typography>0</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="lessDeducRight"
                                sx={{ width: "124px" }}
                              >
                                CAL LOAN
                              </Typography>
                              <Typography>0</Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack direction={"column"} sx={{ width: "100%" }}>
                        <Stack direction="row" spacing={2}>
                          <Stack direction="column">
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contri"
                                sx={{ width: "112px" }}
                              >
                                SSS CONT.
                              </Typography>
                              <Typography>
                                [{decimalFormat(data.sss_rate || 0)}]
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contri"
                                sx={{ width: "112px" }}
                              >
                                MED CONT.
                              </Typography>
                              <Typography>
                                [{decimalFormat(data.philhealth_rate || 0)}]
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contri"
                                sx={{ width: "112px" }}
                              >
                                PAG CONT.
                              </Typography>
                              <Typography>
                                [{decimalFormat(data.pagibig_rate || 0)}]
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contri"
                                sx={{ width: "112px" }}
                              >
                                UT/LATE Min.
                              </Typography>
                              <Typography>{data.ut}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contri"
                                sx={{ width: "112px" }}
                              >
                                UT Amnt
                              </Typography>
                              <Typography>
                                {data.ut * (data.salary_rate / 8)}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction="column">
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contriMiddle"
                                sx={{ width: "70px" }}
                              >
                                CAS
                              </Typography>
                              <Typography>
                                [{data.loans?.monthly_payment || 0}]
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contriMiddle"
                                sx={{ width: "70px" }}
                              >
                                CB
                              </Typography>
                              <Typography>[0]</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contriMiddle"
                                sx={{ width: "70px" }}
                              >
                                PAR
                              </Typography>
                              <Typography>[{data.par || 0}]</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contriMiddle"
                                sx={{ width: "70px" }}
                              >
                                RIC
                              </Typography>
                              <Typography>
                                {decimalFormat(data.rice || 0)}
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contriMiddle"
                                sx={{ width: "70px" }}
                              >
                                LIC
                              </Typography>
                              <Typography>0.00</Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            direction="column"
                            style={{ marginLeft: "26px" }}
                          >
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contriRight"
                                sx={{ width: "120px" }}
                              >
                                V1
                              </Typography>
                              <Typography>[{data.v1 || 0}]</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contriRight"
                                sx={{ width: "120px" }}
                              >
                                V2
                              </Typography>
                              <Typography>[{data.v2 || 0}]</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contriRight"
                                sx={{ width: "120px" }}
                              >
                                ID
                              </Typography>
                              <Typography>[0]</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contriRight"
                                sx={{ width: "120px" }}
                              >
                                HC
                              </Typography>
                              <Typography>0.00</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} ml={2}>
                              <Typography
                                className="contriRight"
                                sx={{ width: "120px" }}
                              >
                                Oth
                              </Typography>
                              <Typography>0.00</Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={2} className="payslip-contri">
                    <Stack direction="column" sx={{ width: "100%" }}>
                      <Stack direction="row" spacing={2}>
                        <Typography sx={{ width: "70%" }}>
                          TOTAL DEDUCTION
                        </Typography>
                        <Typography>
                          ₱{decimalFormat(totalDeduction)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ border: "1px solid" }}
                    className="payslip-footer"
                  >
                    <Typography sx={{ width: "70%", fontWeight: 700 }}>
                      NET HOME PAY
                    </Typography>
                    <Typography sx={{ fontWeight: 700 }}>
                      ₱{decimalFormat(totalSalary - totalDeduction)}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </>
        );
      });
      setPayslipData(payslipData);
    }
  }, [data]);

  return (
    <>
      <IconBreadcrumbs
        data={[
          { link: "/dashboard", title: "Home" },
          {
            link: "/manage-payroll/payroll-group-list",
            title: "Payroll Group List",
          },
          { title: "Pay Slip" },
        ]}
        title={"Pay Slip"}
      />
      <IconMenu type={"EmployeeDTR"} handlePrint={handlePrint} />
      <Grid
        container
        spacing={2}
        ref={componentRef}
        className="payslip-container"
      >
        {payslipData}
      </Grid>
    </>
  );
}

export default PaySlip;
