import React from "react";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";

import PichelLogo from "../../assets/pichel.png";
import EminenceLogo from "../../assets/eminencelogo.png";
import TPDLogo from "../../assets/TPD-logo.jpg";

// Create styles for PDF layout
const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 10,
  },
  logoContainer: {
    alignItems: "center",
    marginRight: 10,
  },
  textContainer: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  logo: {
    width: 100,
    height: 100,
    marginRight: 10,
  },
  title: {
    fontWeight: "bold",
    fontSize: 14,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 12,
  },
});

const PDFHeader = ({ type }) => {
  let head = "";
  let image_logo = "";

  switch (type) {
    case 1:
      image_logo = PichelLogo;
      head = (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Text style={styles.title}>PICHEL SECURITY AND DETECTIVE AGENCY</Text>
          <Text style={styles.subtitle}>TETUAN, ZAMBOANGA CITY</Text>
          <Text style={styles.title}>MASTER LIST</Text>
        </View>
      );
      break;
    case 3:
      head = (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Text style={styles.title}>SSP MANPOWER SERVICES</Text>
          <Text style={styles.subtitle}>TETUAN, ZAMBOANGA CITY</Text>
          <Text style={styles.title}>MASTER LIST</Text>
        </View>
      );
      break;
    case 2:
      image_logo = EminenceLogo;
      head = (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Text style={styles.title}>
            EMINENCE SECURITY AND DETECTIVE AGENCY
          </Text>
          <Text style={styles.subtitle}>TETUAN, ZAMBOANGA CITY</Text>
          <Text style={styles.title}>MASTER LIST</Text>
        </View>
      );
      break;
    default:
      image_logo = "";
      break;
  }

  return (
    <View style={styles.header}>
      <View style={styles.logoContainer}>
        {image_logo && <Image style={styles.logo} src={image_logo} />}
      </View>
      <View style={styles.textContainer}>{head}</View>
    </View>
  );
};

export default PDFHeader;
