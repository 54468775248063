import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#000",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f2f2f2",
    borderBottomWidth: 1,
    borderColor: "#000",
    fontWeight: "bold",
  },
  tableCell: {
    textAlign: "center",
    fontSize: 10,
    padding: 5,
    flexGrow: 1,
  },
  tableHeaderCell: {
    textAlign: "center",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    flexGrow: 1,
  },
  footerText: {
    fontSize: 10,
    marginTop: 10,
    textAlign: "center",
  },
});

export default function MasterList({ bodyContent }) {
  return (
    <View style={styles.table}>
      {/* Table Headers */}
      <View style={styles.tableHeader}>
        <Text style={[styles.tableHeaderCell, { flex: 2 }]}>NAME</Text>
        <Text style={[styles.tableHeaderCell, { flex: 1 }]}>POSITION</Text>
        <Text style={[styles.tableHeaderCell, { flex: 1 }]}>ASSIGN AREA</Text>
        <Text style={[styles.tableHeaderCell, { flex: 1 }]}>WORK STATUS</Text>
        <Text style={[styles.tableHeaderCell, { flex: 1 }]}>DATE HIRED</Text>
        <Text style={[styles.tableHeaderCell, { flex: 1 }]}>DATE END</Text>
      </View>

      {/* Table Rows */}
      {bodyContent && bodyContent.length > 0 ? (
        bodyContent.map((item) => (
          <View style={styles.tableRow} key={item.id}>
            <Text
              style={[
                styles.tableCell,
                { flex: 2, justifyContent: "flex-start", textAlign: "left" },
              ]}
            >
              {`${item.lastname}, ${item.firstname} ${item.middlename}`}
            </Text>
            <Text style={[styles.tableCell, { flex: 1 }]}>
              {item.job_positions}
            </Text>
            <Text style={[styles.tableCell, { flex: 1 }]}>
              {item.area_name}
            </Text>
            <Text style={[styles.tableCell, { flex: 1 }]}>
              {item.work_status}
            </Text>
            <Text style={[styles.tableCell, { flex: 1 }]}>
              {item.date_hired}
            </Text>
            <Text style={[styles.tableCell, { flex: 1 }]}>{item.date_end}</Text>
          </View>
        ))
      ) : (
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, { flex: 6 }]}>No data available</Text>
        </View>
      )}
    </View>
  );
}
