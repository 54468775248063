import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

// Adapter setup
const reportsAdapter = createEntityAdapter();
const initialState = reportsAdapter.getInitialState();

// Format date helper function (optional)
const formatDate = (date) => {
  const d = new Date(date);
  const month = `${d.getMonth() + 1}`.padStart(2, "0");
  const day = `${d.getDate()}`.padStart(2, "0");
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

export const reportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    masterListReport: builder.query({
      query: ({ branch_no }) => {
        const params = new URLSearchParams();
        if (branch_no) params.append("branch_no", branch_no);
        return `/reports/master-list?${params.toString()}`;
      },
      transformResponse: (response) => {
        return response.map((item) => {
          return {
            ...item,
            birthdate: formatDate(item.birthdate),
          };
        });
      },
    }),
    clientListReport: builder.query({
      query: ({ branch_no }) => {
        const params = new URLSearchParams();
        if (branch_no) params.append("branch_no", branch_no);
        return `/reports/client-list?${params.toString()}`;
      },
    }),
  }),
  overrideExisting: true,
  adapter: reportsAdapter,
  entityTypes: ["Reports"],
});

export const { useMasterListReportQuery, useClientListReportQuery } =
  reportApiSlice;
