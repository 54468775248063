import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
//import PrintClient from "../../features/printReport/PrintClient";
import { useReactToPrint } from "react-to-print";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, close, data }) {
  const [title, setTitle] = React.useState(data.title);

  const componentRef = React.useRef();
  const styleRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data.title,
    onBeforeGetContent: () => {
      // Dynamically create and add the print styles
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
        @media print {
          @page {
            size: 8.5in 13in !important;
            margin: 8.13mm 13.46mm !important; /*Narrow*/
            width: 100%; 
          }
          .move-to-first-column {
            order: -1;
            position: absolute;
            right: 130px;
            width: 100%;
            height: 100%;
          }
            .move-to-first-column img {
              width: 100%;
              height: 150px;
              min-width: 200px;
            }

          .personalInfoName{
            width: 150px !important;
            
          }
            .listHolder{
            border-top:none !important;
            border-bottom:none !important; 
            box-shadow:none !important;
            }
            .tableHead{

            }
            .header-class {
              display: block;
            }
            @media print and (page > 1) {
              .header-class {
                display: none;
              }
            }
        }
      `;
      document.head.appendChild(styleElement);
      styleRef.current = styleElement; // Store reference to the style element
    },
    onAfterPrint: () => {
      // Remove the dynamically added style after printing
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
      }
    },
  });

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={close}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            <Button autoFocus color="inherit" onClick={handlePrint}>
              PRINT
            </Button>
          </Toolbar>
        </AppBar>
        <List ref={componentRef} className="listHolder">
          {/*<PrintClient />*/}
        </List>
      </Dialog>
    </React.Fragment>
  );
}
